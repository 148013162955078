import type { GetStaticProps } from "next";

import { getHomePageTranslations } from "../api/translations/homepage";
import { GeneralTranslationsType } from "../api/types/translations";
import HomePage from "../components/Homepage";
import Header from "../components/layout/Header";
import { translationsContent } from "../localization/translations";
import { useLanguage } from "../store/lang.context";
import { translate } from "../utils/translate";

interface Props {
  translations?: [GeneralTranslationsType];
}

/* Just a dummy comment to test deployment to AWS */

export const getStaticProps: GetStaticProps<Props> = async () => {
  const translations = await getHomePageTranslations();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return {
    props: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      translations: translations.data,
    },
  };
};

const Home = ({ translations }: Props) => {
  const { language } = useLanguage();
  return (
    <Header
      title={`EatSalad - ${translate(
        translationsContent,
        "homepageHeader",
        language
      )}`}
      description="eatsalad.com - Salades et plats chauds sur mesure - Franchise"
      noindex={false}
      nofollow={false}
    >
      {translations ? <HomePage translations={translations} /> : <></>}
    </Header>
  );
};

export default Home;
